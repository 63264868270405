import { store } from 'store/index'
import { User } from 'store/modules/users'

export const convertValueToUserIds = (user: User, value?: string) => {
  return userIds(convertValueToUsers(user, value))
}

export const convertValueToUsers = (user: User, value?: string) => {
  switch (value) {
    case 'everybody':
      return store.users.all
    case 'all_reporting_line':
      return user.allReports
    case 'direct_reports':
      return user.directReports
    case 'you_and_all_reporting_line':
    case undefined:
      return user.allReports.concat([user])
    case 'you_and_direct_reports':
      return user.directReports.concat([user])
    default:
      return [store.users.byId(value) || []].flat()
  }
}

const userIds = (users: User[]) => {
  return users.map((user) => user.id)
}
